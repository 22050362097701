import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import Signup from "./Signup";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col
} from "reactstrap";
import Header from "../Header/Header";
import { TableHeader, Pagination, Search } from "../../DataTable"
import useFullPageLoader from "../../hooks/useFullPageLoader"
import Baja from "../../views/Baja";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function ListadoUsuarios() {
  const { user } = useContext(AuthContext);
  const URL_USERS = process.env.REACT_APP_URL_USERS;
  const URL_LISTA_USERS = process.env.REACT_APP_URL_LISTA_USERS;
  const [usuarios, setUsuarios] = useState([]);

  //--- Pagination
const [comments, setComments] = useState([]);
const [loader, showLoader, hideLoader] = useFullPageLoader();
const [totalItems, setTotalItems] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [search, setSearch] = useState("");
const [sorting, setSorting] = useState({ field: "", order: "" });
const [activo, setActivo] = useState("");
const ITEMS_PER_PAGE = 50

const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
const URL_AREAS = process.env.REACT_APP_URL_AREAS;
const [idEdit, setIdEdit] = useState();
const [nombre, setNombre] = useState();
const [apellido, setApellido] = useState();
const [email, setEmail] = useState();
const [is_active, setIsActive] = useState();
const [planteles, setPlanteles] = useState([]);
const [plantel, setPlantel] = useState();
const [areas, setAreas] = useState([]);
const [area, setArea] = useState();

const [idPermisos, setIdPermisos] = useState();
const [menu_planteles, setMenuPlanteles] = useState();
const [menu_areas, setMenuAreas] = useState();
const [menu_ciclos, setMenuCiclos] = useState();

const [menu_fiscal, setMenuFiscal] = useState();
const [facturasFiscal, setFacturasFiscal] = useState();
const [compPagosFiscal, setCompPagosFiscal] = useState();
const [devolucionesFiscal, setDevolucionesFiscal] = useState();
const [notasCreditoFiscal, setNotasCreditoFiscal] = useState();

const [menu_admin, setMenuAdmin] = useState();
const [menu_empresas, setMenuEmpresas] = useState();
const [menu_bancos, setMenuBancos] = useState();
const [menu_ventasAdmin, setMenuVentasAdmin] = useState();
const [menu_edoCta, setMenuEdoCta] = useState();
const [menu_edoCtaVendedor, setMenuEdoCtaVendedor] = useState();
const [menu_abonos, setMenuAbonos] = useState();
const [menu_antiguedad_saldo, setMenuAntiguedadSaldo] = useState();
const [menu_anticipos, setMenuAnticipos] = useState();
const [menu_notasCargo, setMenuNotasCargo] = useState();
const [menu_devolucionesAdmin, setMenuDevolucionesAdmin] = useState();
const [menu_comisiones, setMenuComisiones] = useState();

const [menu_periodos_admin, setMenuPeriodosAdmin] = useState();
const [menu_departamentos, setMenuDepartamentos] = useState();
const [menu_presupuestos, setMenuPresupuestos] = useState();
const [presupuestos_autorizar, setPresupuestosAutorizar] = useState();
const [presupuestos_create, setPresupuestosCreate] = useState();
const [menu_proveedores, setMenuProveedores] = useState();
const [proveedores_create, setProveedoresCreate] = useState();
const [menu_gastos, setMenuGastos] = useState();
const [gastos_create, setGastosCreate] = useState();
const [gastos_autorizar, setGastosAutorizar] = useState();


const [menu_facturas, setMenuFacturas] = useState();
const [menu_clientes, setMenuClientes] = useState();
const [clientes_create, setClientesCreate] = useState();
const [clientes_edo_cta, setClientesEdoCta] = useState();
const [facturas_edo_cta, setFacturasEdoCta] = useState();
const [facturas_cargos, setFacturasCargos] = useState();
const [facturas_cargos_create, setFacturasCargosCreate] = useState();
const [facturas_abonos, setFacturasAbonos] = useState();
const [facturas_abonos_create, setFacturasAbonosCreate] = useState();
const [facturas_abonos_edit, setFacturasAbonosEdit] = useState();
const [menu_catalogos, setMenuCatalogos] = useState();

const [menu_nominas, setMenuNominas] = useState();
const [menu_empleados, setMenuEmpleados] = useState();
const [empleados_create, setEmpleadosCreate] = useState();
const [empleados_sueldo, setEmpleadosSueldo] = useState();
const [menu_puestos, setMenuPuestos] = useState();
const [puestos_create, setPuestosCreate] = useState();
const [nominas_create, setNominasCreate] = useState();
const [nominas_listado, setNominasListado] = useState();
const [nominas_pagos_esp, setNominasPagosEsp] = useState();
const [nominas_pagar, setNominasPagar] = useState();

const [menu_reportes, setMenuReportes] = useState();
const [menu_ingresos, setMenuIngresos] = useState();
const [menu_egresos, setMenuEgresos] = useState();

const [menu_usuarios, setMenuUsuarios] = useState();
const [usuarios_create, setUsuariosCreate] = useState();
const [usuarios_permisos, setUsuariosPermisos] = useState();
const [whatsapp, setWhatsapp] = useState();

const [menu_logs, setMenuLogs] = useState();

const [menu_articulos, setMenuArticulos] = useState();
const [menu_colores, setMenuColores] = useState();
const [colores_create, setColoresCreate] = useState();
const [menu_tipos_telas, setMenuTiposTelas] = useState();
const [tipos_telas_create, setTiposTelasCreate] = useState();
const [articulos_create, setArticulosCreate] = useState();
const [menu_inventarios, setMenuInventarios] = useState();
const [inventarios, setInventarios] = useState();
const [menu_compras, setMenuCompras] = useState();
const [menu_oc, setMenuOC] = useState();
const [oc_create, setOCCreate] = useState();
const [compras, setCompras] = useState();
const [compras_create, setComprasCreate] = useState();
const [menu_ventas, setMenuVentas] = useState();
const [ventas_create, setVentasCreate] = useState();
const [menu_pedidos, setMenuPedidos] = useState();
const [pedidos_create, setPedidosCreate] = useState();
const [menu_devoluciones, setMenuDevoluciones] = useState();
const [devoluciones_create, setDevolucionesCreate] = useState();
const [menu_disponible, setMenuDisponible] = useState();
const [disponible_vendedores, setDisponibleVendedores] = useState();
const [surtidos_create, setSurtidosCreate] = useState();
const [menu_surtidos, setMenuSurtidos] = useState();

const [emailSis, setEmailSis] = useState("admin@sistemify.com");

const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);
const [text, setText] = useState(false);

const [modalPermisos, setModalPermisos] = useState(false);
const togglePermisos = () => setModalPermisos(!modalPermisos);

  useEffect(() => {
    axios
      .get(URL_LISTA_USERS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allUsuarios = response.data;
        console.log("jose",response.data)
        let arrayTabla = allUsuarios
        .sort((a, b) => (a.apellido > b.apellido ? 1 : -1))
        .map((a) => {
          return {
            _id:a._id,
            nombre: a.nombre,
            apellido: a.apellido,
            email: a.email,
            is_active: a.is_active,
            menu_edoCta_Cliente: a.menu_edoCta_Cliente,
            menu_planteles: a.menu_planteles,
            menu_areas: a.menu_areas,
            menu_ciclos: a.menu_ciclos,
            menu_admin: a.menu_admin,
            menu_fiscal: a.menu_fiscal,
            facturasFiscal: a.facturasFiscal,
            compPagosFiscal: a.compPagosFiscal,
            devolucionesFiscal: a.devolucionesFiscal,
            notasCreditoFiscal: a.notasCreditoFiscal,
            menu_empresas: a.menu_empresas,
            menu_bancos: a.menu_bancos,
            menu_ventasAdmin: a.menu_ventasAdmin,
            menu_edoCta: a.menu_edoCta,
            menu_edoCtaVendedor: a.menu_edoCtaVendedor,
            menu_abonos: a.menu_abonos,
            menu_antiguedad_saldo: a.menu_antiguedad_saldo,
            menu_anticipos: a.menu_anticipos,
            menu_notasCargo: a.menu_notasCargo,
            menu_devolucionesAdmin: a.menu_devolucionesAdmin,
            menu_comisiones: a.menu_comisiones,
            menu_periodos_admin: a.menu_periodos_admin,
            menu_departamentos: a.menu_departamentos,
            menu_presupuestos: a.menu_presupuestos,
            presupuestos_autorizar: a.presupuestos_autorizar,
            presupuestos_create: a.presupuestos_create,
            menu_proveedores: a.menu_proveedores,
            proveedores_create: a.proveedores_create,
            menu_gastos: a.menu_gastos,
            gastos_create: a.gastos_create,
            gastos_autorizar: a.gastos_autorizar,
            menu_facturas: a.menu_facturas,
            menu_clientes: a.menu_clientes,
            clientes_create: a.clientes_create,
            clientes_edo_cta: a.clientes_edo_cta,
            facturas_edo_cta: a.facturas_edo_cta,
            facturas_cargos: a.facturas_cargos,
            facturas_cargos_create: a.facturas_cargos_create,
            facturas_abonos: a.facturas_abonos,
            facturas_abonos_create: a.facturas_abonos_create,
            menu_catalogos: a.menu_catalogos,
            menu_nominas: a.menu_nominas,
            menu_empleados: a.menu_empleados,
            empleados_create: a.empleados_create,
            empleados_sueldo: a.empleados_sueldo,
            menu_puestos: a.menu_puestos,
            puestos_create: a.puestos_create,
            nominas_create: a.nominas_create,
            nominas_listado: a.nominas_listado,
            nominas_pagos_esp: a.nominas_pagos_esp,
            nominas_pagar: a.nominas_pagar,
            menu_reportes: a.menu_reportes,
            menu_ingresos: a.menu_ingresos,
            menu_egresos: a.menu_egresos,
            menu_usuarios: a.menu_usuarios,
            usuarios_create: a.usuarios_create,
            usuarios_permisos: a.usuarios_permisos,
            whatsapp: a.whatsapp,
            facturas_abonos_edit: a.facturas_abonos_edit,
            menu_logs: a.menu_logs,
            menu_cobradores: a.menu_cobradores,
            menu_articulos: a.menu_articulos,
            menu_colores: a.menu_colores,
            colores_create: a.colores_create,
            menu_tipos_telas: a.menu_tipos_telas,
            tipos_telas_create: a.tipos_telas_create,
            articulos_create: a.articulos_create,
            menu_inventarios: a.menu_inventarios,
            inventarios: a.inventarios,
            menu_compras: a.menu_compras,
            menu_oc: a.menu_oc,
            oc_create: a.oc_create,
            compras: a.compras,
            compras_create: a.compras_create,
            menu_ventas: a.menu_ventas,
            ventas_create: a.ventas_create,
            menu_pedidos: a.menu_pedidos,
            pedidos_create: a.pedidos_create,
            menu_devoluciones: a.menu_devoluciones,
            devoluciones_create: a.devoluciones_create,
            menu_disponible: a.menu_disponible,
            disponible_vendedores: a.disponible_vendedores,
            menu_surtidos: a.menu_surtidos,
            surtidos_create: a.surtidos_create,
          }
        }).filter(function (el) {
          return el != null;
        });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal)
        setUsuarios(allUsuarios);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPlanteles = res.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Apellido", field: "apellido", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.is_active.toLowerCase().includes(search.toLowerCase()) ||
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                comment.apellido.toLowerCase().includes(search.toLowerCase()) ||
                comment.email.toLowerCase().includes(search.toLowerCase())
        );
    }


    if(activo){
      computedComments = computedComments.filter((e)=>
      e.is_active.includes(activo)
      ) 
    } 

    if(emailSis){
      computedComments = computedComments.filter((e)=>
      !e.email.includes(emailSis)
      ) 
    }     

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, activo, email]);

function Edit(idEdit, nombre, apellido, email){
  axios
  .get(`${URL_USERS}/${idEdit}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  }).then((response)=>{
    let usuarioEdit = response.data;
    setPlantel(usuarioEdit.planteles[0]._id)
    setArea(usuarioEdit.areas[0]._id)
  })
  setIdEdit(idEdit)
  setNombre(nombre)
  setApellido(apellido)
  setEmail(email)
  toggle()
}

function editUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idEdit}`,
      {
        nombre,
        apellido,
        email,
        planteles: plantel,
        areas: area,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      toggle();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function Permisos (
  id, 
  nombre,
  apellido,
  menu_planteles,
  menu_areas,
  menu_ciclos,
  menu_admin,
  menu_fiscal,
  facturasFiscal,
  compPagosFiscal,
  devolucionesFiscal,
  notasCreditoFiscal,
  menu_empresas,
  menu_bancos,
  menu_ventasAdmin,
  menu_edoCta,
  menu_edoCtaVendedor,
  menu_abonos,
  menu_antiguedad_saldo,
  menu_anticipos,
  menu_notasCargo,
  menu_devolucionesAdmin,
  menu_comisiones,
  menu_periodos_admin,
  menu_departamentos,
  menu_presupuestos,
  presupuestos_autorizar,
  presupuestos_create,
  menu_proveedores,
  proveedores_create,
  menu_gastos,
  gastos_create,
  gastos_autorizar,
  menu_facturas,
  menu_clientes,
  clientes_create,
  clientes_edo_cta,
  facturas_edo_cta,
  facturas_cargos,
  facturas_cargos_create,
  facturas_abonos,
  facturas_abonos_create,
  menu_catalogos,
  menu_nominas,
  menu_empleados,
  empleados_create,
  empleados_sueldo,
  menu_puestos,
  puestos_create,
  nominas_create,
  nominas_listado,
  nominas_pagos_esp,
  nominas_pagar,
  menu_reportes,
  menu_ingresos,
  menu_egresos,
  menu_usuarios,
  usuarios_create,
  usuarios_permisos,
  whatsapp,
  facturas_abonos_edit,
  menu_logs,
  menu_articulos,
  menu_colores,
  colores_create,
  menu_tipos_telas,
  tipos_telas_create,
  articulos_create,
  menu_inventarios,
  inventarios,
  menu_compras,
  menu_oc,
  oc_create,
  compras,
  compras_create,
  menu_ventas,
  ventas_create,
  menu_pedidos,
  pedidos_create,
  menu_devoluciones,
  devoluciones_create,
  menu_disponible,
  disponible_vendedores,
  menu_surtidos,
  surtidos_create,
  ){
        setIdPermisos(id)
        setNombre(nombre)
        setApellido(apellido)
        setMenuAdmin(menu_admin)
        setMenuFiscal(menu_fiscal)
        setFacturasFiscal(facturasFiscal)
        setCompPagosFiscal(compPagosFiscal)
        setDevolucionesFiscal(devolucionesFiscal)
        setNotasCreditoFiscal(notasCreditoFiscal)
        setMenuEmpresas(menu_empresas)
        setMenuBancos(menu_bancos)
        setMenuVentasAdmin(menu_ventasAdmin)
        setMenuEdoCta(menu_edoCta)
        setMenuEdoCtaVendedor(menu_edoCtaVendedor)
        setMenuAbonos(menu_abonos)
        setMenuAntiguedadSaldo(menu_antiguedad_saldo)
        setMenuAnticipos(menu_anticipos)
        setMenuNotasCargo(menu_notasCargo)
        setMenuDevolucionesAdmin(menu_devolucionesAdmin)
        setMenuComisiones(menu_comisiones)
        setMenuFacturas(menu_facturas)
        setMenuNominas(menu_nominas)
        setMenuReportes(menu_reportes)
        setMenuUsuarios(menu_usuarios)
        setMenuPlanteles(menu_planteles)
        setMenuAreas(menu_areas)
        setMenuCiclos(menu_ciclos)
        setMenuPeriodosAdmin(menu_periodos_admin)
        setMenuDepartamentos(menu_departamentos)
        setMenuPresupuestos(menu_presupuestos)
        setPresupuestosAutorizar(presupuestos_autorizar)
        setPresupuestosCreate(presupuestos_create)
        setMenuProveedores(menu_proveedores)
        setMenuGastos(menu_gastos)
        setMenuClientes(menu_clientes)
        setMenuCatalogos(menu_catalogos)
        setMenuEmpleados(menu_empleados)
        setMenuPuestos(menu_puestos)
        setMenuIngresos(menu_ingresos)
        setMenuEgresos(menu_egresos)
        setNominasCreate(nominas_create)
        setProveedoresCreate(proveedores_create)
        setGastosCreate(gastos_create)
        setGastosAutorizar(gastos_autorizar)
        setClientesCreate(clientes_create)
        setClientesEdoCta(clientes_edo_cta)
        setFacturasEdoCta(facturas_edo_cta)
        setFacturasCargos(facturas_cargos)
        setFacturasCargosCreate(facturas_cargos_create)
        setFacturasAbonos(facturas_abonos)
        setFacturasAbonosCreate(facturas_abonos_create)
        setEmpleadosCreate(empleados_create)
        setEmpleadosSueldo(empleados_sueldo)
        setPuestosCreate(puestos_create)
        setNominasListado(nominas_listado)
        setNominasPagosEsp(nominas_pagos_esp)
        setNominasPagar(nominas_pagar)
        setUsuariosCreate(usuarios_create)
        setUsuariosPermisos(usuarios_permisos)
        setWhatsapp(whatsapp)
        setFacturasAbonosEdit(facturas_abonos_edit)
        setMenuLogs(menu_logs)
        setMenuArticulos(menu_articulos)
        setMenuColores(menu_colores)
        setColoresCreate(colores_create)
        setMenuTiposTelas(menu_tipos_telas)
        setTiposTelasCreate(tipos_telas_create)
        setArticulosCreate(articulos_create)
        setMenuInventarios(menu_inventarios)
        setInventarios(inventarios)
        setMenuCompras(menu_compras)
        setMenuOC(menu_oc)
        setOCCreate(oc_create)
        setCompras(compras)
        setComprasCreate(compras_create)
        setMenuVentas(menu_ventas)
        setVentasCreate(ventas_create)
        setMenuPedidos(menu_pedidos)
        setPedidosCreate(pedidos_create)
        setMenuDevoluciones(menu_devoluciones)
        setDevolucionesCreate(devoluciones_create)
        setMenuDisponible(menu_disponible)
        setDisponibleVendedores(disponible_vendedores)
        setMenuSurtidos(menu_surtidos)
        setSurtidosCreate(surtidos_create)
        togglePermisos()
}

function permisosUsuario(event) {
  event.preventDefault();
  axios
    .patch(
      `${URL_USERS}/${idPermisos}`,
      {
        menu_planteles,
        menu_areas,
        menu_ciclos,
        menu_fiscal,
        facturasFiscal,
        devolucionesFiscal,
        notasCreditoFiscal,
        compPagosFiscal,
        menu_admin,
        menu_empresas,
        menu_bancos,
        menu_ventasAdmin,
        menu_edoCta,
        menu_edoCtaVendedor,
        menu_abonos,
        menu_antiguedad_saldo,
        menu_anticipos,
        menu_notasCargo,
        menu_devolucionesAdmin,
        menu_comisiones,
        menu_periodos_admin,
        menu_departamentos,
        menu_presupuestos,
        presupuestos_autorizar,
        presupuestos_create,
        menu_proveedores,
        proveedores_create,
        menu_gastos,
        gastos_create,
        gastos_autorizar,
        menu_facturas,
        menu_clientes,
        clientes_create,
        clientes_edo_cta,
        facturas_edo_cta,
        facturas_cargos,
        facturas_cargos_create,
        facturas_abonos,
        facturas_abonos_create,
        menu_catalogos,
        menu_nominas,
        menu_empleados,
        empleados_create,
        empleados_sueldo,
        menu_puestos,
        puestos_create,
        nominas_create,
        nominas_listado,
        nominas_pagos_esp,
        nominas_pagar,
        menu_reportes,
        menu_ingresos,
        menu_egresos,
        menu_usuarios,
        usuarios_create,
        usuarios_permisos,
        whatsapp,
        facturas_abonos_edit,
        menu_logs,
        menu_articulos,
        menu_colores,
        colores_create,
        menu_tipos_telas,
        tipos_telas_create,
        articulos_create,
        menu_inventarios,
        inventarios,
        menu_compras,
        menu_oc,
        oc_create,
        compras,
        compras_create,
        menu_ventas,
        ventas_create,
        menu_pedidos,
        pedidos_create,
        menu_devoluciones,
        devoluciones_create,
        menu_disponible,
        disponible_vendedores,
        menu_surtidos,
        surtidos_create,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      togglePermisos();
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
     .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

  return (
    <>
    <Header />
    <br />
    {user.menu_usuarios  ?(
        <div className="row">
        {
          <div className="col-12">
            <div className="card container">
              <div className="card-body">
                <h3 align="center">Usuarios</h3>
                <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
            <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                    <tbody>
                    {commentsData
                    .map((p) => {
                        // if (p.menu_edoCta_Fam == false && p.menu_edoCta_Cliente == false && p.email != "admin@sistemify.com") {
                      return (
                        <tr>
                          <td>{p.is_active}</td>
                          <td>{p.nombre}</td>
                          <td>{p.apellido}</td>
                          <td>{p.email}</td>
                          <td>
                          {user.usuarios_create?(
                          <Button color="info" id="Editar" size="sm" onClick={e=>{Edit(p._id, p.nombre, p.apellido, p.email)}}>
                            <i class="far fa-edit"></i>
                          </Button>
                        ):(
                          <Button color="info" id="Editar" size="sm" disabled>
                            <i class="far fa-edit"></i>
                          </Button>
                        )}
                            {user.usuarios_permisos  && user.is_active =="Si" ?(
                            <Button color="success" id="Permisos" size="sm" onClick={e=>{Permisos(
                              p._id, 
                              p.nombre,
                              p.apellido,
                              p.menu_planteles,
                              p.menu_areas,
                              p.menu_ciclos,
                              p.menu_admin,
                              p.menu_fiscal,
                              p.facturasFiscal,
                              p.compPagosFiscal,
                              p.devolucionesFiscal,
                              p.notasCreditoFiscal,
                              p.menu_empresas,
                              p.menu_bancos,
                              p.menu_ventasAdmin,
                              p.menu_edoCta,
                              p.menu_edoCtaVendedor,
                              p.menu_abonos,
                              p.menu_antiguedad_saldo,
                              p.menu_anticipos,
                              p.menu_notasCargo,
                              p.menu_devolucionesAdmin,
                              p.menu_comisiones,
                              p.menu_periodos_admin,
                              p.menu_departamentos,
                              p.menu_presupuestos,
                              p.presupuestos_autorizar,
                              p.presupuestos_create,
                              p.menu_proveedores,
                              p.proveedores_create,
                              p.menu_gastos,
                              p.gastos_create,
                              p.gastos_autorizar,
                              p.menu_facturas,
                              p.menu_clientes,
                              p.clientes_create,
                              p.clientes_edo_cta,
                              p.facturas_edo_cta,
                              p.facturas_cargos,
                              p.facturas_cargos_create,
                              p.facturas_abonos,
                              p.facturas_abonos_create,
                              p.menu_catalogos,
                              p.menu_nominas,
                              p.menu_empleados,
                              p.empleados_create,
                              p.empleados_sueldo,
                              p.menu_puestos,
                              p.puestos_create,
                              p.nominas_create,
                              p.nominas_listado,
                              p.nominas_pagos_esp,
                              p.nominas_pagar,
                              p.menu_reportes,
                              p.menu_ingresos,
                              p.menu_egresos,
                              p.menu_usuarios,
                              p.usuarios_create,
                              p.usuarios_permisos,
                              p.whatsapp,
                              p.facturas_abonos_edit,
                              p.menu_logs,
                              p.menu_articulos,
                              p.menu_colores,
                              p.colores_create,
                              p.menu_tipos_telas,
                              p.tipos_telas_create,
                              p.articulos_create,
                              p.menu_inventarios,
                              p.inventarios,
                              p.menu_compras,
                              p.menu_oc,
                              p.oc_create,
                              p.compras,
                              p.compras_create,
                              p.menu_ventas,
                              p.ventas_create,
                              p.menu_pedidos,
                              p.pedidos_create,
                              p.menu_devoluciones,
                              p.devoluciones_create,
                              p.menu_disponible,
                              p.disponible_vendedores,
                              p.menu_surtidos,
                              p.surtidos_create,
                              )}}>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            ):(
                              <Button color="success" id="Permisos" size="sm" disabled>
                            <i class="fas fa-key"></i>{" "}
                          </Button>
                            )}
                            {user.usuarios_create?(
                          <Baja
                            idStatus={p._id}
                            is_active={p.is_active}
                            URL_BAJA={process.env.REACT_APP_URL_USERS}
                          />
                            ):undefined}
                          </td>
                        </tr>
                      );
                      // }
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        }
        {/* <div className="col-4">
          <div className="card">
            <div className="card-body">
              <Signup />
            </div>
          </div>
        </div> */}
        </div>
      ): undefined } 

<Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}><h4>Editar Usuario {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nombre}
            required
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Apellido</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={apellido}
            required
            onChange={(e) => {
              setApellido(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={email}
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {/* <Label>Plantel</Label>
              <Input
                type="select"
                value={plantel}
                required
                onChange={(e) => {
                  setPlantel(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {planteles.map((a) => {
                  return <option value={a._id}>{a.name}</option>;
                })}
              </Input>
              <Label className="mr-sm-2">Areas</Label>
                <Input
                  type="select"
                  value={area}
                  required
                  onChange={(e) => setArea(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {areas
                    .sort((a, b) => a.name - b.name)
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input> */}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editUsuario}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {/* //Permisos */}
      <Modal size="xl" isOpen={modalPermisos} toggle={togglePermisos}>
        <ModalHeader toggle={togglePermisos}><h4>Permisos de {nombre} {apellido} </h4></ModalHeader>
        <ModalBody>
        <div className="container">
        <br/>
        <Row>

      {/* <Col md={3}>
    <h5>CLIENTES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuFacturas(event.currentTarget.checked)}
        checked={menu_facturas}
      /><h5 className="subMenuTitulo">Menu Clientes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuClientes(event.currentTarget.checked)}
        checked={menu_clientes}
        className="subMenu"
      /><h5 className="subMenuTitulo">Clientes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setClientesCreate(event.currentTarget.checked)}
        checked={clientes_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Clientes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFacturasEdoCta(event.currentTarget.checked)}
        checked={facturas_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Clientes Edo Cta</h5>
      <Input
        type="checkbox"
        onChange={(event) => setClientesEdoCta(event.currentTarget.checked)}
        checked={clientes_edo_cta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Detalle Notas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFacturasCargos(event.currentTarget.checked)}
        checked={facturas_cargos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Notas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFacturasCargosCreate(event.currentTarget.checked)}
        checked={facturas_cargos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Nota</h5>
       <Input
        type="checkbox"
        onChange={(event) => setFacturasAbonos(event.currentTarget.checked)}
        checked={facturas_abonos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFacturasAbonosCreate(event.currentTarget.checked)}
        checked={facturas_abonos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setFacturasAbonosEdit(event.currentTarget.checked)}
        checked={facturas_abonos_edit}
        className="subMenu"
      /><h5 className="subMenuTitulo">Editar Abonos</h5>

      </Col> */}

    <Col md={3}>
    <h5>ARTICULOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuArticulos(event.currentTarget.checked)}
        checked={menu_articulos}
      /><h5 className="subMenuTitulo">Articulos</h5>
        <Input
        type="checkbox"
        onChange={(event) => setArticulosCreate(event.currentTarget.checked)}
        checked={articulos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Articulos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuColores(event.currentTarget.checked)}
        checked={menu_colores}
        className="subMenu"
      /><h5 className="subMenuTitulo">Colores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setColoresCreate(event.currentTarget.checked)}
        checked={colores_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Colores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuTiposTelas(event.currentTarget.checked)}
        checked={menu_tipos_telas}
        className="subMenu"
      /><h5 className="subMenuTitulo">Tipos de Tela</h5>
      <Input
        type="checkbox"
        onChange={(event) => setTiposTelasCreate(event.currentTarget.checked)}
        checked={tipos_telas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Tipos de Tela</h5> 

      </Col>

      <Col md={3}>
      <h5>COMPRAS</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuProveedores(event.currentTarget.checked)}
        checked={menu_proveedores}
        className="subMenu"
      /><h5 className="subMenuTitulo">Proveedores</h5>
      <Input
        type="checkbox"
        onChange={(event) => setProveedoresCreate(event.currentTarget.checked)}
        checked={proveedores_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Proveedores</h5>
       <Input
        type="checkbox"
        onChange={(event) => setMenuCompras(event.currentTarget.checked)}
        checked={menu_compras}
        className="subMenu"
      /><h5 className="subMenuTitulo">Compras</h5>
      <Input
        type="checkbox"
        onChange={(event) => setComprasCreate(event.currentTarget.checked)}
        checked={compras_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Compras</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuOC(event.currentTarget.checked)}
        checked={menu_oc}
        className="subMenu"
      /><h5 className="subMenuTitulo">Ordenes de Compra</h5>
      <Input
        type="checkbox"
        onChange={(event) => setOCCreate(event.currentTarget.checked)}
        checked={oc_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Ordenes de Compra</h5>
      </Col>

      <Col md={3}>
      <h5>VENTAS</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuVentas(event.currentTarget.checked)}
        checked={menu_ventas}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Ventas</h5>
       <Input
        type="checkbox"
        onChange={(event) => setVentasCreate(event.currentTarget.checked)}
        checked={ventas_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Ventas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuClientes(event.currentTarget.checked)}
        checked={menu_clientes}
        className="subMenu"
      /><h5 className="subMenuTitulo">Clientes</h5>
      <Input
        type="checkbox"
        onChange={(event) => setClientesCreate(event.currentTarget.checked)}
        checked={clientes_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Clientes</h5>
       <Input
        type="checkbox"
        onChange={(event) => setMenuPedidos(event.currentTarget.checked)}
        checked={menu_pedidos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Pedidos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPedidosCreate(event.currentTarget.checked)}
        checked={pedidos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Pedidos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setMenuSurtidos(event.currentTarget.checked)}
        checked={menu_surtidos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Surtidos</h5>
       <Input
        type="checkbox"
        onChange={(event) => setSurtidosCreate(event.currentTarget.checked)}
        checked={surtidos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Surtidos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuDevoluciones(event.currentTarget.checked)}
        checked={menu_devoluciones}
        className="subMenu"
      /><h5 className="subMenuTitulo">Devoluciones</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDevolucionesCreate(event.currentTarget.checked)}
        checked={devoluciones_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Devoluciones</h5>

      </Col>

      <Col md={3}>
      <h5>INVENTARIO</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuInventarios(event.currentTarget.checked)}
        checked={menu_inventarios}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Inventarios</h5>
      <Input
        type="checkbox"
        onChange={(event) => setInventarios(event.currentTarget.checked)}
        checked={inventarios}
        className="subMenu"
      /><h5 className="subMenuTitulo">Inventarios</h5>
       <Input
        type="checkbox"
        onChange={(event) => setMenuDisponible(event.currentTarget.checked)}
        checked={menu_disponible}
        className="subMenu"
      /><h5 className="subMenuTitulo">Disponible</h5>
      <Input
        type="checkbox"
        onChange={(event) => setDisponibleVendedores(event.currentTarget.checked)}
        checked={disponible_vendedores}
        className="subMenu"
      /><h5 className="subMenuTitulo">Disponible Vendedores</h5>
          
      </Col>

      <Col md={3}>
    <h5>EMPLEADOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuNominas(event.currentTarget.checked)}
        checked={menu_nominas}
      /><h5 className="subMenuTitulo">Menu Empleados</h5>
      
      <Input
        type="checkbox"
        onChange={(event) => setMenuEmpleados(event.currentTarget.checked)}
        checked={menu_empleados}
        className="subMenu"
      /><h5 className="subMenuTitulo">Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setEmpleadosCreate(event.currentTarget.checked)}
        checked={empleados_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setEmpleadosSueldo(event.currentTarget.checked)}
        checked={empleados_sueldo}
        className="subMenu"
      /><h5 className="subMenuTitulo">Sueldo Empleados</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuPuestos(event.currentTarget.checked)}
        checked={menu_puestos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Puestos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setPuestosCreate(event.currentTarget.checked)}
        checked={puestos_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Puestos</h5>
      </Col>

      <Col md={3}>
    <h5>REPORTES</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuReportes(event.currentTarget.checked)}
        checked={menu_reportes}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Reportes</h5>
      {/* <Input
        type="checkbox"
        onChange={(event) => setMenuIngresos(event.currentTarget.checked)}
        checked={menu_ingresos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Reporte Ingresos</h5> 
       <Input
        type="checkbox"
        onChange={(event) => setMenuEgresos(event.currentTarget.checked)}
        checked={menu_egresos}
      /><h5>Reporte Egresos</h5>  */}

      <h5>USUARIOS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuUsuarios(event.currentTarget.checked)}
        checked={menu_usuarios}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Usuarios</h5>
      <Input
        type="checkbox"
        onChange={(event) => setUsuariosCreate(event.currentTarget.checked)}
        checked={usuarios_create}
        className="subMenu"
      /><h5 className="subMenuTitulo">Crear Usuarios</h5>
      <Input
        type="checkbox"
        onChange={(event) => setUsuariosPermisos(event.currentTarget.checked)}
        checked={usuarios_permisos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Permisos Usuarios</h5>
       <br />
      <br />
      </Col>
      <Col>
      <h5>ADMIN</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuAdmin(event.currentTarget.checked)}
        checked={menu_admin}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Admin</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuEmpresas(event.currentTarget.checked)}
        checked={menu_empresas}
        className="subMenu"
      /><h5 className="subMenuTitulo">Empresas</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuBancos(event.currentTarget.checked)}
        checked={menu_bancos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Bancos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuVentasAdmin(event.currentTarget.checked)}
        checked={menu_ventasAdmin}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Ventas Admin</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuEdoCta(event.currentTarget.checked)}
        checked={menu_edoCta}
        className="subMenu"
      /><h5 className="subMenuTitulo">Estado de Cuenta</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuEdoCtaVendedor(event.currentTarget.checked)}
        checked={menu_edoCtaVendedor}
        className="subMenu"
      /><h5 className="subMenuTitulo">Estado de Cuenta Vendedor</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuAbonos(event.currentTarget.checked)}
        checked={menu_abonos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Abonos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuAnticipos(event.currentTarget.checked)}
        checked={menu_anticipos}
        className="subMenu"
      /><h5 className="subMenuTitulo">Anticipos</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuNotasCargo(event.currentTarget.checked)}
        checked={menu_notasCargo}
        className="subMenu"
      /><h5 className="subMenuTitulo">Notas Cargo</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuDevolucionesAdmin(event.currentTarget.checked)}
        checked={menu_devolucionesAdmin}
        className="subMenu"
      /><h5 className="subMenuTitulo">Devoluciones Admin</h5>
      <Input
        type="checkbox"
        onChange={(event) => setMenuComisiones(event.currentTarget.checked)}
        checked={menu_comisiones}
        className="subMenu"
      /><h5 className="subMenuTitulo">Comisiones</h5>

<Input
        type="checkbox"
        onChange={(event) => setMenuAntiguedadSaldo(event.currentTarget.checked)}
        checked={menu_antiguedad_saldo}
        className="subMenu"
      /><h5 className="subMenuTitulo">Antiguedad Saldo</h5>
      
      
      </Col>
      <Col>

      <h5>LOGS</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuLogs(event.currentTarget.checked)}
        checked={menu_logs}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu Logs</h5>
      <Input
        type="checkbox"
        onChange={(event) => setWhatsapp(event.currentTarget.checked)}
        checked={whatsapp}
        className="subMenu"
      /><h5 className="subMenuTitulo">WhatsApp</h5>


<br />
<br />
<h5>FISCAL</h5>
        <Input
        type="checkbox"
        onChange={(event) => setMenuFiscal(event.currentTarget.checked)}
        checked={menu_fiscal}
        className="subMenu"
      /><h5 className="subMenuTitulo">Menu</h5>
      <Input
      type="checkbox"
      onChange={(event) => setFacturasFiscal(event.currentTarget.checked)}
      checked={facturasFiscal}
      className="subMenu"
    /><h5 className="subMenuTitulo">Facturas</h5>  
    <Input
        type="checkbox"
        onChange={(event) => setDevolucionesFiscal(event.currentTarget.checked)}
        checked={devolucionesFiscal}
        className="subMenu"
      /><h5 className="subMenuTitulo">Devoluciones</h5>
      <Input
      type="checkbox"
      onChange={(event) => setNotasCreditoFiscal(event.currentTarget.checked)}
      checked={notasCreditoFiscal}
      className="subMenu"
    /><h5 className="subMenuTitulo">Notas Credito</h5>
    <Input
    type="checkbox"
    onChange={(event) => setCompPagosFiscal(event.currentTarget.checked)}
    checked={compPagosFiscal}
    className="subMenu"
  /><h5 className="subMenuTitulo">Complementos de Pagos</h5>
      </Col>
      </Row>
      </div>
        </ModalBody>
        <ModalFooter>
          <div className="container">
          <Button color="success" 
          onClick={permisosUsuario}
          >
            Guardar
          </Button>
          </div>
        </ModalFooter>
      </Modal>

{loader}
    </>
  );
}

export default ListadoUsuarios;
